@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Manrope', system-ui, sans-serif;
    @apply bg-slate-900;
    @apply text-sky-50 !important
  }
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply uppercase;
    @apply text-slate-950; 
    @apply font-bold;
    @apply text-3xl;
  }
  h3 {
    @apply text-lg;
    @apply font-bold;
    @apply tracking-wider;
  }
}

body {
  margin: 0;
  padding: 0;
  color: white !important;
  @apply bg-slate-900 !important;
}

body p {
  font-size: calc(13px + 0.3vw) !important;
}

#root {
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @apply bg-slate-900;
}

*, *::after, *::before{
  box-sizing: border-box;
}

.hero-content {
  flex: 1;
  padding: 2rem; /* Adjust as needed */
}

.bg-mobile {
  background-size: cover;
}

.bg-tablet {
  background-size: cover;
}

.bg-desktop {
  background-size: cover;
  border: 1px solid red;
}

label {
  font-weight: 700;
}

hr {
  opacity: .5;
  width: 200px;
  height: 2px;
}

a:link {
  text-decoration: none;
}

.innerLink {
  color: rgb(62 146 250) !important;
}

.credits {
  text-decoration: none !important;
  opacity: 150% !important;
}

.create{
  position: relative;
}

.navDropdown{
  display: none;
  position: absolute;
  top: 64px;
  width: auto;
  height: auto;
  background: rgb(2 6 23 / var(--tw-bg-opacity));
  color: #000;
  box-shadow: 0 3px 6px 0 rgb(19 19 19 / 50%);
  text-align: left;
  padding-left: 0px;
  border: 1px solid slategrey;
  border-top: none;
}

.navDropdown{
  display: none;
  position: absolute;
  top: 64px;
  width: auto;
  height: auto;
  background: rgb(2 6 23 / var(--tw-bg-opacity));
  color: #000;
  box-shadow: 0 3px 6px 0 rgb(19 19 19 / 50%);
  text-align: left;
  padding-left: 0px;
  border: 1px solid slategrey;
  border-top: none;
}

.eventsDropdown {
  padding-left: 0px;
  padding-bottom: 0px;
  text-align: center;
  margin-bottom: 0px;
  border: 0;
  border-bottom: slategrey;
  border-top: slategrey;
}


.dditem{
  margin: 0;
  padding: 12px;
  padding-right: 15px;
}

.dditem a{
  height: 100%
}


.Events:hover .navDropdown {
  display: block;

}

.navDropdown li:hover{
  transition: 0.3s;
  /* #747474 */
}

.navDropdown:hover .dditem a{
  background-color: transparent;
}

.formContainer {
  flex-direction: row;
}

.formImage {
  padding-bottom: 69px;
}

.formImageContainer {
}

.donationImage {
  max-width: 100%; /* Ensure the image doesn't exceed its container's width */
  max-height: 100%; /* Set a maximum height for the image */
  width: auto; /* Allow the image to adjust its width */
  object-fit: contain; /* Maintain the image's aspect ratio while fitting within the container */
}

@media (max-width: 680px) {
  .formContainer {
    flex-direction: column !important;
    max-width: 100%; /* Ensure the image doesn't exceed its container's width */
    max-height: 100%; /* Set a maximum height for the image */
    gap: 30px;
    padding-bottom: 0px !important;
    height: auto !important;
    padding: 0 !important;
  }

  .formImage {
    max-width: 100%; /* Ensure the image doesn't exceed its container's width */
    max-height: 100%; /* Set a maximum height for the image */
    width: auto; /* Allow the image to adjust its width */
    object-fit: contain !important; /* Maintain the image's aspect ratio while fitting within the container */
    height: auto !important;
    padding: 0 !important;
  }

  .formImageContainer {
    max-width: 100%; /* Ensure the image doesn't exceed its container's width */
    max-height: 100%; /* Set a maximum height for the image */
    width: auto; /* Allow the image to adjust its width */
    object-fit: contain; /* Maintain the image's aspect ratio while fitting within the container */
    height: auto !important;
    padding: 0 !important;
  }

  .donationImageContainer {
  }
}


.googleFrameContain {
  height: 58vh;
  width: 45vw;
}

.googleFrame {
  height: 100%;
  width: 100%;
  margin-right: 10%;
}

.rehearsalInfoContainer {
  flex-direction: row;
}


@media (max-width: 460px) {
  .rehearsalInfoContainer {
    flex-direction: column !important;
  }
  .googleFrameContain {
    width: 84vw;
  }
}

@media (max-width: 400px) {
  .vomoLogo {
    content:url("../misc/vomoIconLtBlue.png");
  }
}

.banner {
  height: 90vh;
}

.bannerText {
      /* margin-top: 21%; */
    /* padding: 19%; */
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: calc(14px + 0.5vw);
    /* margin: auto; */
    /* padding-top: 35%; */
    /* margin-top: 60px; */
    justify-content: center;
}

.bannerText p {
  font-size: calc(23px + 0.5vw) !important;
  margin-left: 10%;
  margin-right: 10%;
}

.modal-content, .modal-dialog {
  background: none !important;
  max-width:100% !important;
}

.carousel {
  height: inherit;
}

.carouselImage {
  max-height: 85vh;
  height: 100%;
}

.carouselImage img {
  display: inline-block;
  width: 100%;
  margin: 0;
  height: 100%;
  object-fit: cover !important;
}

.carousel-inner {
  height: 100%;
}

.carousel-item {
  height: 100%
}

.carouselContainer {
  height: 85vh;
}

.carousel-item .carousel-control-next .carousel-control-prev {
  transition: -webkit-transform .6s ease !important;
  transition: transform .6s ease !important;
  transition: transform .6s ease,-webkit-transform .6s ease !important;
} 

.homePageButton {
  background-color: #3b82f6;
  color: #fff;
  border: none;
  border-radius: 8px;
  width: 115px;
  height: 45px;
  transition: .3s;
  font-size: small;
  font-family: 'Manrope', system-ui, sans-serif;
  font-weight: bold;
}

.homePageButton:hover {
  background-color: #5a98fa;
  box-shadow: 0 0 0 5px #3b83f65f;
  color: #fff; 
}

.callToAction {
  font-size: 15px;
  height: 51px;
  width: 115px;
}

.nondiscriminationPolicy {
  font-size: calc(8px + 0.5vw) !important;
  text-align: justify;
  opacity: 0.65;
}

/* Calendar Cards */

.calendarCardContainer {
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    gap: 7vw;
    padding-bottom: 60px;
    padding-top: 10px;
    padding-bottom: 52px;
    width: 64vw;
    margin: 0 auto;
    max-width: 1297px;
}

@media (max-width: 580px) {
  .calendarCardContainer {
    flex-direction: column;
  }
}

@media (max-width: 850px) {
  .calendarCardContainer {
    gap: 4vw !important;
  }
}

.calendar {
  position: relative;
  width: 35vw;
}

.calendar .calendar-body{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  border-bottom: 6px solid rgb(62 146 250);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0 5px 25px rgb(1 1 1 / 15%);
  overflow-x: clip;
}

.calendar .calendar-body .month-name{
  color: #fff;
  background: rgb(62 146 250);
  width: 100%;
  font-size: calc(14px + 0.5vh);
  text-align: center;
  font-weight: 400;
  padding: 5px 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.calendar .calendar-body .day-name{
  color: #fff;
  font-size: calc(12px + 0.5vh);
  font-weight: 400;
  margin-top: 11px;
}

.calendar .calendar-body .date-number{
  color: #fff;
  font-size: calc(29px + 0.5vh);
  font-weight: 600;
  line-height: 1.2em;
}

.calendar .calendar-body .eventTitle{
  color: #fff;
  font-size: calc(12px + 0.5vh);
  font-weight: 400;
  margin-bottom: 33px;
  margin-top: 35px;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}


/* Info Cards */

.vocamotionSummary {
	width: 80%;
	margin: 0 auto;
	margin-bottom: 50px;
  color: white;
  font-size: calc(13px + 0.3vw);
}

.small {
  height: 2px;
}

a, a:hover {
	text-decoration: none;
	transition: color 0.3s ease-in-out;
}

.postcard_title a {
  color: rgb(62 146 250) !important;
}

a:hover {
  color: rgb(112, 167, 250) !important;
}

#pageHeaderTitle {
	margin: 2rem 0;
	text-transform: uppercase;
	text-align: center;
	font-size: calc(33px + 0.5vw);
  color: white;
}

#subPageHeaderTitle {
  margin: 0 auto !important;
	text-transform: uppercase;
	text-align: center;
	font-size: calc(33px + 0.5vw);
  color: white;
}

.photoCard .imageDescription {
  font-size: calc(14px + 0.5vw);
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.gridItem {
  text-align: center;
  padding: 2vh;
  padding-bottom: 9vh;
}

@media (max-width: 650px) {
  .gridContainer {
    grid-template-columns: 2fr;
  }
}

@media (max-width: 3px) {
  .gridContainer {
    grid-template-columns: 2fr;
  }
}

.contactEmail {
  font-size: calc(9px + 0.5vw);
}

.chorusTitle {
  font-size: calc(12px + 0.5vw);
}


/* Info Cards */
.postcard {
  flex-wrap: wrap;
  display: flex;
  
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  margin: 0 0 2rem 0;
  overflow: hidden;
  position: relative;
  background-color: rgb(30 41 59);
  color: white;
}
	
	h1,	.h1 {
		margin-bottom: 0.5rem;
		font-weight: 500;
		line-height: 1.2;
	}
	
	.small {
		font-size: 80%;
	}

  .postcard_title {
    font-size: calc(20px + 0.5vw);
  }

  .postcard_img {
    max-height: 180px;
    width: 100%;
    object-fit: cover;
    position: relative;
  }

  .postcard_img:hover{
    transform: scale(1.05);
    cursor: pointer;
  }

  .postcard_img_link {
    display: contents;
  }

  .postcard_bar {
    width: 50px;
    height: 10px;
    margin: 10px 0;
    border-radius: 5px;
  }

  .postcard_text {
    padding: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .postcard_preview-txt {
    text-overflow: ellipsis;
    text-align: justify;
    height: 100%;
    font-size: calc(12px + 0.3vw);
  }

@media screen and (min-width: 769px) {
  .postcard {
    flex-wrap: inherit;
  }

    .postcard_img {
      max-width: 300px;
      max-height: 100%;
      transition: transform 0.3s ease;
    }

    .postcard_text {
      padding: 3rem;
      width: 100%;
    }

    .media.postcard_text:before {
      content: "";
      position: absolute;
      display: block;
      background: #18151f;
      top: -20%;
      height: 130%;
      width: 55px;
    }
    .postcard:nth-child(2n+1) {
      flex-direction: row;
    }

    .postcard:nth-child(2n+0) {
      flex-direction: row-reverse;
    }

    .postcard:nth-child(2n+1) .postcard_text::before {
      left: -12px !important;
      transform: rotate(4deg);
    }

    .postcard:nth-child(2n+0) .postcard_text::before {
      right: -12px !important;
      transform: rotate(-4deg);
    }
  }

@media screen and (min-width: 1024px){
		.postcard_text {
      padding: 2rem 3.5rem;
    }
		
		.postcard_text:before {
      content: "";
      position: absolute;
      display: block;
      
      top: -20%;
      height: 130%;
      width: 55px;
    }
  }
.blue .postcard_title:hover {
	color: skyblue
}
.blue .postcard_bar {
	background-color: skyblue
}


/* Modal Images */
/* Style the Image Used to Trigger the Modal */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {opacity: 0.7;}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content, #caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}

